<template>
    
    <page page="omgeving" theme="light" type="page">

        <div class="action--bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <a href="#scrollto-dorp" data-position="1">
                            <div class="icon"><img src=@/assets/img/icons/home.svg></div>
                            <span>Het dorp</span>
                        </a>
                        <a href="#scrollto-omgeving" data-position="2">
                            <div class="icon"><img src=@/assets/img/icons/tree-alt.svg></div>
                            <span>Omgeving</span>
                        </a>
                        <a href="#scrollto-efteling" data-position="3">
                            <div class="icon"><img src=@/assets/img/icons/castle.svg></div>
                            <span>Efteling</span>
                        </a>
                        <a href="#scrollto-plaatsen" data-position="4">
                            <div class="icon"><img src=@/assets/img/icons/city.svg></div>
                            <span>Plaatsen</span>
                        </a>
                        <a href="#scrollto-kurenpolder" data-position="5">
                            <div class="icon"><img src=@/assets/img/icons/swimming-pool.svg></div>
                            <span>Kurenpolder</span>
                        </a>
                        <a href="#scrollto-bioscoop" data-position="6">
                            <div class="icon"><img src=@/assets/img/icons/movie.svg></div>
                            <span>Bioscoop</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <slider-block 
            name="dorp"
            position="right" 
            background="default"
            :images="['kasteel.jpg', 'wandelpad.jpg', 'boot-2.jpg']" 
            :timing="3000" 
            prefix="omgeving/big/">

            <h3>Het dorp</h3>
            <p>
                De B&amp;B ligt heerlijk rustig aan de rand van het dorp Dussen, maar niet afgelegen. Het is op loopafstand van de bakker, supermarkt, slager en diverse restaurants. 
            </p>
            <p>
                Verder zijn er meerdere bezienswaardigheden op loopafstand waaronder Klompenmakerij Den Dekker, Kasteel Dussen, de Maas en de veerpont en de ‘wereldberoemde’ kledingzaak Ballegooijen Modes, natuurgebied De Kornsche Boezem met de Noordveldse molen.
            </p>
        </slider-block>

        <slider-block 
            name="omgeving"
            position="left" 
            background="dark"
            :images="['arrangementen/big/biesbosch-3.jpg', 'omgeving/big/molen.jpg', 'omgeving/big/fietsroute-veraf.jpg']" 
            :timing="4000">

            <h3>Omgeving</h3>
            <p>
                Dussen is een prima uitvalsbasis voor wandelaars, fietsers en auto -en motorliefhebbers, die het Land van Heusden en Altena en het natuurpark ‘De Biesbosch’ willen verkennen. 
                De grootste water delta van Europa met een grote diversiteit aan trekvogels, zeearend en visarend, schotse hooglanders, bevers enz.
            </p>
            <span><router-link to="/arrangementen" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/users.svg" />Onze arrangementen</router-link></span>
        </slider-block>

        <slider-block 
            name="efteling"
            position="right" 
            background="default"
            :images="['efteling-winter.jpg']">

            <h3>Efteling</h3>
            <p>
                En natuurlijk niet te vergeten, het grootste attractiepark van Nederland ‘De Efteling’ en het is zowel in de zomer als in de winter te bezoeken. De afstand tot de Efteling is 14 km. 
                Vanaf Dussen is er een mooie route met het gratis pontje binnendoor naar de Efteling om de files te vermijden en in de zomermaanden op de fiets goed bereikbaar en het is ook een mooie route!
            </p>
            <a href="https://www.efteling.com/nl/park/tickets" target="_blank" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/castle.svg" />Bezoek de Efteling</a>
        </slider-block>

        <slider-block 
            name="plaatsen"
            position="left" 
            background="dark"
            :images="['dorpen-en-steden.jpg']">

           <h3>Omliggende plaatsen</h3>
            <p>
                De nabijgelegen dorpen en steden zijn zeker ook een aanrader, zoals Geertruidenberg (8 km), Heusden (20 km), Woudrichem (12 km). 
                In het seizoen kunt u vanaf Woudrichem met een voetveer naar Slot Loevestein overgezet worden. 
                Ook kunt u er ‘de Dokter Tinus’ route wandelen en een bezoek brengen aan het Visserijmuseum aan de historische haven.
            </p>
            <p>
                De B&amp;B ‘de Oude Stoffeerderij’ ligt heel centraal om de omliggende grote steden te bezoeken met alle bezienswaardigheden, 
                zoals Breda (20 km), ’s Hertogenbosch (25 km), Utrecht (40 km), Rotterdam (40 km), Amsterdam (70 km) en zelfs Antwerpen maar (70 km).
            </p>
        </slider-block>

        <slider-block 
            name="kurenpolder"
            position="right" 
            background="default"
            :images="['kurenpolder.png']">

            <h3>Kurenpolder</h3>
            <p>
                Op recreatiepark de Kurenpolder in Hank (4 km) kunt u heerlijk zwemmen in het subtropisch zwembad met een 100 meter lange glijbaan en in de zomer kunt u een heerlijk dagje naar het strand in Brabant. Verder is er ook een klimpark op het terrein.
            </p>
            <a href="https://www.kurenpolder.nl/" target="_blank" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/swimming-pool.svg" />Bezoek de Kurenpolder</a>
        </slider-block>

        <slider-block 
            name="bioscoop"
            position="left" 
            background="dark"
            :images="['hollywoud.jpg']">

            <h3>Bioscoop</h3>
            <p>
                Verder is er een bioscoop in Almkerk genaamd ‘Hollywoud’. Kenmerkend aan deze bioscoop is, dat u tijdens de film drankjes en hapjes geserveerd kunt krijgen.
            </p>
            <a href="http://www.hollywoud.nl/main.php" target="_blank" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/movie.svg" />Bezoek de bioscoop</a>
        </slider-block>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
import SliderBlock from '../modules/AppSliderBlock.vue';

export default {
    metaInfo: {
        title: 'Omgeving',
        meta: [
            { vmid: 'description', name: 'description', content: 'De B&B ligt heerlijk rustig aan de rand van het dorp Dussen, maar niet afgelegen. Het is op loopafstand van de bakker, supermarkt, slager en diverse restaurants.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/omgeving'}
        ]
    },
    data() {
        return {
            
        }
    },
    computed: {
        swipeExplain() {
            return window.innerWidth < 768 && this.tutorial;
        }
    },
    components: {
        Page,
        SliderBlock
    },
    mounted() {
        var vm = this;
        /* eslint-disable */
        $('.slider').each(function(index){
            var slider = $(this);
            var sliderInitializer = slider.find('ul.slides');
            sliderInitializer.find('>li').addClass('slide');
            var childnum = sliderInitializer.find('li').length;
            
            var themeDefaults = {
                cellSelector: '.slide',
                cellAlign: 'left',
                wrapAround: true,
                pageDots: false,
                prevNextButtons: false,
                autoPlay: true,
                draggable: (childnum < 2 ? false: true),
                imagesLoaded: true,
                accessibility: true,
                rightToLeft: false,
                initialIndex: 0,
                freeScroll: false
            }; 

            // Attribute Overrides - options that are overridden by data attributes on the slider element
            var ao = {};
            ao.pageDots = (slider.attr('data-paging') === 'true' && sliderInitializer.find('li').length > 1) ? true : undefined;
            ao.prevNextButtons = slider.attr('data-arrows') === 'true'? true: undefined;
            ao.draggable = slider.attr('data-draggable') === 'false'? false : undefined;
            ao.autoPlay = slider.attr('data-autoplay') === 'false'? false: (slider.attr('data-timing') ? parseInt(slider.attr('data-timing'), 10): undefined);
            ao.accessibility = slider.attr('data-accessibility') === 'false'? false : undefined;
            ao.rightToLeft = slider.attr('data-rtl') === 'true'? true : undefined;
            ao.initialIndex = slider.attr('data-initial') ? parseInt(slider.attr('data-initial'), 10) : undefined;
            ao.freeScroll = slider.attr('data-freescroll') === "true" ? true: undefined;

            // Set data attribute to inidicate the number of children in the slider
            slider.attr('data-children',childnum);

            
            $(this).data('sliderOptions', jQuery.extend({}, themeDefaults, {}, ao));

            $(sliderInitializer).flickity($(this).data('sliderOptions'));

            $(sliderInitializer).on( 'scroll.flickity', function() {
              if(slider.find('.is-selected').hasClass('controls--dark')){
                slider.addClass('controls--dark');
              }else{
                slider.removeClass('controls--dark'); 
              }
            });

            slider.on("mousedown touchstart", () => {
                vm.tutorial = false;
            });
        });
        /* eslint-enable */
    }
}
</script>
